import { DownloadIcon, LeftArrowIcon } from '../../../../../assets/icons';
import { Button } from '../../../../../components/UI';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAppointmentReadableDateTime,
  getReadableDate,
} from '../../../../../utils/date.utl';
import { ArrowDown, ArrowUp } from 'lucide-react';
import { useResultDownload } from '../../../../../hooks';

const LabResults: React.FC = () => {
  const { downloadTDLResult } = useResultDownload();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedTest: TDLRequestedTestResponse = location.state.selectedTest;

  const { patient, testResult } = selectedTest;
  const result = (result: string) => {
    return result.replace(/\\/g, '\\\\');
  };

  const handlePrint = async () => {
    downloadTDLResult(selectedTest);
  };
  const showInfo = (label: string, value: string | number | boolean) => {
    return (
      <div className="flex justify-between">
        <p className="font-semibold mb-1 text-neutral-500">{label}</p>
        <p>{value}</p>
      </div>
    );
  };

  return (
    <>
      <div className="border-b border-neutral-100 p-3 relative flex justify-between items-center">
        <Button leadingIcon={<LeftArrowIcon />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <h1 className="text-neutral-900 font-medium whitespace-nowrap text-center">
          Patient Results
        </h1>
        <div className="flex">
          <Button
            leadingIcon={<DownloadIcon />}
            onClick={() => handlePrint()}
            className="mr-3"
          >
            Download
          </Button>
          {/* <Button
            variant={'outlined'}
            size={'xmall'}
            leadingIcon={<PrinterIcon />}
            onClick={() => navigate(-1)}
          >
            Print
          </Button> */}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 py-8 px-2">
        <div className="p-4 border border-gray-300 rounded-lg">
          <h5 className="text-xl font-semibold mb-4">Patient Details</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {showInfo('Patient id', patient.publicId)}
            {showInfo('Reference id', selectedTest.testReferenceId)}
            {showInfo('Patient name', patient.fullName)}
            {showInfo('Gender at birth', patient.gender)}
            {showInfo('Date of birth', getReadableDate(patient.dateOfBirth))}
            {showInfo('Address', patient.address.fullAddress)}
          </div>
          <hr className="my-4" />
          <h5 className="text-xl font-semibold mb-4">Test Results</h5>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {showInfo(
              'Sample Date',
              getAppointmentReadableDateTime(new Date(testResult.SampleDate))
            )}
            {showInfo(
              'Report Date',
              getAppointmentReadableDateTime(new Date(testResult.ReportedDate))
            )}
          </div>
          <hr className="my-4" />

          {selectedTest.testResult.Tests.map(
            (testResult: TestResult, index: number) => (
              <div key={index} className="grid ">
                <div className="col-span-full mb-2">
                  <h5 className="text-xl font-semibold">
                    {testResult.TestName}
                  </h5>
                </div>
                {testResult.ResultLines.map((item: ResultLine, idx: number) => (
                  <div key={idx} className=" sm:grid-cols-6 mb-3 ">
                    <div className="grid grid-cols-6 gap-x-0  bg-info-50 p-2">
                      <div className="grid-cols-2">
                        <p
                          className={`courier ${
                            item.Flag === 'High' ? 'text-red-500' : ''
                          }`}
                        >
                          {item.TestName}
                        </p>
                      </div>
                      <div className="cols-1"></div>
                      <div className="grid-cols-4">
                        <pre
                          className={`courier ${
                            item.Flag === 'High'
                              ? 'high text-red-500'
                              : item.Flag === 'Low'
                                ? 'low'
                                : item.Flag === 'A'
                                  ? 'abnorm'
                                  : ''
                          }`}
                        >
                          {result(item.Result)}
                        </pre>
                      </div>
                      <div
                        className={`grid-cols-1 ${
                          item.Flag === 'High' ? 'text-red-500' : ''
                        }`}
                      >
                        {item.Flag === 'High' && <ArrowUp />}{' '}
                        {item.Flag === 'Low' && <ArrowDown />}
                      </div>
                      <div className="grid-cols-2">
                        <p
                          className={`courier ${
                            item.Flag === 'High' ? 'text-red-500' : ''
                          }`}
                        >
                          {item.UOM}
                        </p>
                      </div>
                      <div className="grid-cols-2">
                        <p
                          className={`courier ${
                            item.Flag === 'High' ? 'text-red-500' : ''
                          }`}
                        >
                          {item.RangeText}
                        </p>
                      </div>
                    </div>
                    {idx === testResult.ResultLines.length - 1 && <hr />}
                  </div>
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default LabResults;
