import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '../../utils';
import { ButtonProps } from '../../types/uiComponents/type';
import { Slot } from '@radix-ui/react-slot';
import { LoaderIcon2 } from '../../assets/icons';

const buttonVariants = cva(
  'px-[10px] md:px-[16px] py-[13px]  w-[fit-content] flex justify-center items-center gap-2 rounded-[4px] text-sm font-medium whitespace-nowrap disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'text-shades-0 bg-primary-500 hover:ouline hover:outline-primary-600 focus:border-[2px] focus:border-primary-600 disabled:bg-primary-100 disabled:text-neutral-50 disabled:border-none',
        secondary:
          'text-primary-600 border border-primary-500 hover:bg-primary-50 hover:border hover:border hover:border-primary-600 focus:border-[2px] focus:border-primary-600 disabled:bg-neutral-100 disabled:text-primary-400 disabled:border-none',
        outlined:
          'text-neutral-700 border border-neutral-700 bg-shades-0 hover:bg-neutral-50 focus:bg-neutral-100 disabled:text-neutral-400 disabled:border-neutral-300',
        link: 'text-neutral-700 bg-[transparent] disabled:text-neutral-400 rounded-[0px] disabled:cursor-not-allowed disabled:border-none',
        destructive:
          'text-shades-0 bg-destructive-500 hover:border hover:border-destructive-400 focus:border focus:border-destructive-400 disabled:bg-destructive-300 disabled:text-destructive-200 disabled:border-none',
        success:
          'text-shades-0 bg-success-500 hover:border hover:border-success-400 focus:border focus:border-success-400 disabled:text-success-400 disabled:bg-success-200 disabled:border-none',
        warning:
          'text-shades-0 bg-warning-500 hover:outline hover:outline-warning-600 focus:border-[2px] focus:border-primary-600 disabled:bg-warning-100 disabled:text-neutral-50 disabled:border-none',
      },
      size: {
        large: 'h-[44px]',
        medium: 'h-[40px]',
        small: 'h-[36px]',
        xmall: 'h-[32px]',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'large',
    },
  }
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    size = 'xmall',
    className,
    variant = 'outlined',
    leadingIcon,
    trailingIcon,
    isLoading,
    children,
    asChild = false,
    disabled,
    ...props
  }) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={isLoading || disabled}
        {...props}
      >
        {!isLoading && leadingIcon}

        {children}

        {isLoading && <LoaderIcon2 />}
        {!isLoading && trailingIcon}
      </Comp>
    );
  }
);

export { Button, buttonVariants };
